import React, { useState, useEffect, useRef } from 'react';
import { Box, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === '/') {
        e.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    onSearch(value);
  };

  const handleClearSearch = () => {
    setQuery('');
    onSearch('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          borderRadius: '25px',
          width: { xs: '85%', md: '50%', lg: '60%' },
          padding: '5px 20px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconButton>
          <SearchIcon sx={{ color: '#fff', marginRight: '10px' }} />
        </IconButton>
        <InputBase
          inputRef={inputRef}
          placeholder="Search products"
          value={query}
          onChange={handleSearchChange}
          sx={{
            color: '#fff',
            caretColor: '#fff', 
            width: '100%',
            fontFamily: 'monospace',
            fontSize: { xs: '16px', md: '16px' },
          }}
        />
        {query && (
          <IconButton onClick={handleClearSearch}>
            <ClearIcon sx={{ color: '#fff' }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default SearchBar;
