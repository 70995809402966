import React, { useEffect } from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AppNavBar from '../common_components/AppNavBar';
import confetti from 'canvas-confetti';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';

const OrderConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const { orderDetails } = location.state || {};

  useEffect(() => {
    if (orderDetails) {
      confetti({
        particleCount: 300,
        spread: 70,
        startVelocity: 30,
        origin: { y: 0.5 },
      });
    }
  }, [orderDetails]);

  if (!orderDetails) {
    return (
      <>
        <AppNavBar />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
          <Typography variant="h5" sx={{ color: '#00796b' }}>Order details are not available.</Typography>
          <Button onClick={() => navigate('/')} sx={buttonStyles}>
            Go to Home
          </Button>
        </Box>
      </>
    );
  }

  const { restaurantName, deliveryDate, restaurantEmail } = orderDetails;

  const buttonStyles = {
    background: 'linear-gradient(90deg, #a5eb98)',
    color: '#00796b',
    fontFamily: 'monospace',
    borderRadius: '10px',
    fontSize: '14px',
    padding: '10px 20px',
    transition: 'background-color 0.4s ease, transform 0.3s ease',
    mt: 2,
    '&:hover': {
      background: 'linear-gradient(to right, #43cea2, #00796b)',
      transform: 'scale(1.03)',
      color: '#ffffff',
    },
  };

  return (
    <>
      <AppNavBar showSearchBar={false} showShopingCart={false} />
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', minHeight: '100vh', mt:4, padding: { xs: '0 16px', md: '0 24px' }, overflow: 'hidden' }}>
        <CheckCircleOutlineIcon sx={{ color: '#4CAF50', fontSize: '80px'}} />
        
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#00796b', fontFamily: 'monospace', mb: 2 }}>
          Order Confirmed!
        </Typography>
        
        <Typography variant="body1" sx={{ color: '#555', fontFamily: 'monospace', mb: 4 }}>
          A confirmation has been sent to your email: <strong>{restaurantEmail}</strong>
        </Typography>

        <Card sx={{ maxWidth: 500, width: '100%', backgroundColor: '#f9fdfd', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', borderRadius: '15px', padding: 2 }}>
          <CardContent>
            <Typography variant="h5" sx={{ color: '#00796b', fontFamily: 'monospace', mb: 2 }}>
              Thank you, {restaurantName}!
            </Typography>
            <Typography sx={{ color: '#185a9d', fontFamily: 'monospace', mb: 3 }}>
              Your order will be delivered on <strong>{deliveryDate}</strong>.
            </Typography>
            <EmailIcon sx={{ color: '#00796b', fontSize: '40px', mb: 2 }} />
            <Typography variant="body2" sx={{ color: '#555', fontFamily: 'monospace', mb: 2 }}>
            Thank you for choosing our products! Your order details have been sent to your email.
            </Typography>
            <Button onClick={() => navigate('/')} sx={buttonStyles}>
              Back to Home
            </Button>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default OrderConfirmationPage;
