import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    cartItems: {},
    cartCount: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      const item = action.payload;
      const existingItem = state.cartItems[item.id] || { ...item, quantity: 0 };
      
      if (existingItem.stock > 0) {
        existingItem.quantity += 1;
        existingItem.stock -= 1; // Decrease stock
        state.cartItems[item.id] = existingItem;
        state.cartCount += 1;
      }
    },
    removeFromCart: (state, action) => {
      const itemId = action.payload;
      const existingItem = state.cartItems[itemId];
    
      if (existingItem && existingItem.quantity > 0) {
        const updatedQuantity = existingItem.quantity - 1;
        const updatedStock = existingItem.stock + 1;
    
        if (updatedQuantity === 0) {
          delete state.cartItems[itemId];
        } else {
          // Create a new object to ensure immutability
          state.cartItems[itemId] = {
            ...existingItem,
            quantity: updatedQuantity,
            stock: updatedStock,
          };
        }
        state.cartCount -= 1;
      }
    },
    
    deleteItemFromCart: (state, action) => {
      const itemId = action.payload;
      const item = state.cartItems[itemId];
  
      if (item) {
        state.cartCount -= item.quantity;
        delete state.cartItems[itemId];
      }
    },
    clearCart: (state) => {
      state.cartItems = {};
      state.cartCount = 0;
    },
  },
});

export const { addToCart, removeFromCart, deleteItemFromCart, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
