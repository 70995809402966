import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NoItemsFound = ({ searchQuery }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '50vh', // Vertically center
        padding: { xs: '20px', sm: '40px' },
      }}
    >
      <SearchOffIcon 
        sx={{ 
          fontSize: { xs: '80px', sm: '100px' }, 
          color: 'gray', 
          marginBottom: '20px' 
        }} 
      />
      <Typography
        variant="h5"
        sx={{
          fontFamily: 'monospace',
          textAlign: 'center',
          marginBottom: '20px',
          fontSize: { xs: '16px', sm: '20px' },
          color: '#333',
        }}
      >
        No items found for <strong>{searchQuery}</strong>.
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontFamily: 'monospace',
          textAlign: 'center',
          color: 'gray',
          fontSize: { xs: '14px', sm: '16px' },
        }}
      >
        Please try searching for something else or browse the available items from the list.
      </Typography>

      <Button
        variant="contained"
        sx={{
          marginTop: '20px',
          backgroundColor: '#00796b',
          '&:hover': { backgroundColor: '#004d40' },
          fontFamily: 'monospace',
        }}
        onClick={() => window.location.reload()}
      >
        Browse Items
      </Button>
    </Box>
  );
};

export default NoItemsFound;
