import React from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Box
      sx={{
        position: 'fixed',
        width: '101%',
        padding: isMobile ? 2 : 3,
        backgroundColor: '#00796b',
        color: '#fff',
        textAlign: 'center',
        mt: isMobile ? 107 : 76,
      }}
    >
      <Typography variant="body2" sx={{ fontSize: isMobile ? '12px' : '16px' }}>
        &copy; 2024 GrubPack a Boxtogo LLC company. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
