import React, { useState, useRef, useEffect } from 'react';
import { Box, List, Typography, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import productList from '../productList.json';

const Sidebar = ({ onCategoryClick, selectedCategory }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [fadeOpacity, setFadeOpacity] = useState(1);
  const scrollableContentRef = useRef(null);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const checkScroll = () => {
      if (scrollableContentRef.current) {
        const { scrollHeight, scrollTop, clientHeight } = scrollableContentRef.current;
        const isScrollable = scrollHeight > clientHeight;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 5;

        setShowScrollButton(!isAtBottom && isScrollable);
        setFadeOpacity(isAtBottom ? 0 : 1);
      }
    };

    checkScroll();

    const refCurrent = scrollableContentRef.current;
    refCurrent?.addEventListener('scroll', checkScroll);

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener('scroll', checkScroll);
      }
    };
  }, []);

  const handleScrollDown = () => {
    if (scrollableContentRef.current) {
      scrollableContentRef.current.scrollBy({ top: 100, behavior: 'smooth' });
    }
  };

  return (
    <Box
      sx={{
        width: isOpen ? { xs: '100%', sm: 220 } : 60,
        padding: '15px',
        backgroundColor: '#fff',
        height: { xs: '100vh', sm: '80%' },
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 0.4s ease',
        position: 'fixed', // Fix the sidebar to the left of the screen
        top: 0,
        left: 0,
        zIndex: 1300, // Keep it above other content
        overflow: 'hidden',
      }}
    >
      {isOpen && (
        <Typography
          sx={{
            color: '#00796b',
            fontFamily: 'monospace',
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '40px',
            whiteSpace: 'nowrap',
          }}
        >
          Product Categories
        </Typography>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        {isOpen && (
          <Typography
            variant="subtitle2"
            onClick={() => onCategoryClick('All Products')}
            sx={{
              fontWeight: 'bold',
              padding: '6px 12px',
              color: selectedCategory === 'All Products' ? '#fff' : '#00796b',
              cursor: 'pointer',
              fontFamily: 'monospace',
              borderRadius: '10px',
              fontSize: '12px',
              backgroundColor: selectedCategory === 'All Products' ? '#00796b' : 'inherit',
              '&:hover': { color: '#4db6ac', transition: 'color 0.3s ease' },
            }}
          >
            All Products
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#00796b',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f0f0f0',
          },
        }}
        ref={scrollableContentRef}
      >
        <List sx={{ padding: 0 }}>
          {productList.map((category) => (
            <Box key={category.id} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              {isOpen && (
                <Typography
                  variant="subtitle2"
                  onClick={() => onCategoryClick(category)}
                  sx={{
                    fontWeight: 'bold',
                    padding: '6px 12px',
                    color: selectedCategory?.id === category.id ? '#fff' : '#00796b',
                    cursor: 'pointer',
                    fontFamily: 'monospace',
                    borderRadius: '10px',
                    fontSize: '12px',
                    backgroundColor: selectedCategory?.id === category.id ? '#00796b' : 'inherit',
                    '&:hover': { color: '#4db6ac', transition: 'color 0.3s ease' },
                  }}
                >
                  {category.name}
                </Typography>
              )}
            </Box>
          ))}
        </List>
      </Box>

      {/* Dynamic fade-out effect for desktop only */}
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 30,
            left: 0,
            width: '100%',
            height: 30,
            background: 'linear-gradient(to bottom, rgba(255,255,255,0), #fff)',
            opacity: fadeOpacity,
            transition: 'opacity 0.3s ease',
            pointerEvents: 'none',
          }}
        />
      )}

      {/* Scroll down button, visible only if more content can be scrolled on desktop */}
      {!isMobile && showScrollButton && (
        <IconButton
          onClick={handleScrollDown}
          sx={{
            position: 'absolute',
            bottom: 0,
            left: { xs: '50%', sm: '30%' },
            transform: 'translateX(-50%)',
            color: '#00796b',
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Sidebar;
