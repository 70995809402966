import React from 'react';
import { AppBar, Toolbar, IconButton, Badge, Box, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ShoppingCartCheckoutOutlined as CartIcon } from '@mui/icons-material';
import SearchBar from './SearchBar';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../assets/images/grubpack-logo.jpg';

const AppNavBar = ({ onMenuClick, onSearch, isDrawerOpen, showSearchBar = true, showShopingCart = true }) => {
  const cartCount = useSelector((state) => state.cart.cartCount);
  const location = useLocation();
  const navigate = useNavigate();

  const hideMenuIcon = ['/cart', '/orderconfirmation'].includes(location.pathname);
  const isOnListPage = location.pathname === '/list';
  const isDesktop = useMediaQuery('(min-width:960px)');

  return (
    <AppBar
      position="fixed"
      sx={{
        background: 'linear-gradient(45deg, #00796b, #4db6ac)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xs: '10px', md: '0 20px' },
        }}
      >
        <IconButton
          sx={{
            display: hideMenuIcon ? 'none' : { md: 'none' },
            transition: 'transform 0.3s ease', // Adds smooth rotation
            transform: isDrawerOpen ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate if drawer is open
          }}
          edge="start"
          color="inherit"
          onClick={onMenuClick}
        >
          {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>

        {!isOnListPage || isDesktop ? (
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/')}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: '80px',
                height: '50px',
                borderRadius: '10%',
                cursor: 'pointer',
              }}
              sx={{
                width: { xs: '60px', sm: '80px' },
                height: { xs: '40px', sm: '50px' },
              }}
            />
          </Box>
        ) : null}

        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
          {showSearchBar && <SearchBar onSearch={onSearch} />}
        </Box>

        {showShopingCart && (
          <IconButton
            color="inherit"
            component={Link}
            to="/cart"
            sx={{
              ...(cartCount > 0 && {
                animation: 'buzz 0.9s infinite alternate',
                '@keyframes buzz': {
                  from: { transform: 'scale(1)', boxShadow: '0 0 0px 0px rgba(255, 0, 0, 0.8)' },
                  to: { transform: 'scale(1.1)', boxShadow: '0 0 8px 4px rgba(255, 0, 0, 0.8)' },
                },
              }),
            }}
          >
            <Badge badgeContent={cartCount} color="error">
              <CartIcon />
            </Badge>
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default AppNavBar;