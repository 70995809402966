import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ChooseStore from './common_components/ChooseStore';
import MainLayout from './MainLayout';
import ReviewOrderPage from './Cart/ReviewOrderPage';
import OrderConfirmation from './OrderConfirmation/OrderConfirmation';

const App = () => {
  const [selectedCity, setSelectedCity] = useState(
    () => localStorage.getItem('selectedCity') || null
  );

  const handleSelectCity = (city) => {
    setSelectedCity(city);
    localStorage.setItem('selectedCity', city);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<ChooseStore onSelectCity={handleSelectCity} />}
        />
        <Route
          path="/list"
          element={
            selectedCity
              ? <MainLayout city={selectedCity} />
              : <Navigate to="/" />
          }
        />
        <Route path="/cart" element={<ReviewOrderPage />} />
        <Route path="/orderconfirmation" element={<OrderConfirmation />} />
      </Routes>
    </Router>
  );
};

export default App;
